import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ASU from '../assets/ASU.jpeg';
import Genpact from '../assets/Genpact.png';
import Dochase from '../assets/dochase.png'; // Import Dochase logo

const experiences = [
  {
    role: 'Software Developer Intern',
    company: 'Dochase Adx Digital',
    duration: '08/2024 - Present',
    points: [
      'Spearheaded the development and optimization of comprehensive technical documentation, including RESTful APIs, user manuals, and Standard Operating Procedures (SOPs), leveraging Markdown and LaTeX to enhance clarity and accessibility, resulting in a 20% reduction in user error reports.',
      'Engineered and maintained scalable full-stack web applications using React.js and Node.js, implementing dynamic, responsive UI components and RESTful APIs, improving user experience and reducing page load times by 30%.',
      'Integrated third-party APIs and services to streamline data flow and enhance application functionality, utilizing OAuth and JWT authentication to ensure secure data transactions.'
    ],
    logo: Dochase,
    url: 'https://www.dochase.com' // URL to Dochase Adx Digital website
  },
  {
    role: 'Operations and Technology Coordinator',
    company: 'Arizona State University',
    duration: '01/2023 - 08/2024', // Updated duration
    points: [
      'Led daily operations at the Student Union, overseeing a team of 15 people and implementing technology-driven solutions to enhance operational efficiency. Interfaced with over 50 clients daily, utilizing technical, leadership, and communication skills to ensure seamless building operations and client satisfaction in a dynamic environment',
    ],
    logo: ASU,
    url: 'https://www.asu.edu' // URL to ASU website
  },
  {
    role: 'Software Engineering Assistant',
    company: 'Arizona State University',
    duration: '01/2023 - 05/2023',
    points: [
      'Engineered robust software solutions by developing and optimizing code in Java and Python for the university\'s course management and student information systems, resulting in a 15% improvement in application performance by enhancing data retrieval processes and reducing latency.',
      'Pioneered a peer review system using Git workflows, improving code quality and version control efficiency, reducing deployment errors by 30%, and expediting development cycles with automated testing and continuous integration.'
    ],
    logo: ASU,
    url: 'https://www.asu.edu' // URL to ASU website
  },
  {
    role: 'Undergraduate Teaching Assistant',
    company: 'Arizona State University',
    duration: '08/2022 - 12/2022',
    points: [
      'Mentored 85 freshman engineering students in collaborative software projects, utilizing p5.js to develop interactive visualizations, significantly enhancing their ability to understand and apply complex engineering concepts.',
      'Facilitated the course instructor in planning and organizing lectures, assignments, and exams for a class of 200 students, while conducting weekly office hours to provide one-on-one or group assistance, clarifying concepts, and debugging code.'
    ],
    logo: ASU,
    url: 'https://www.asu.edu' // URL to ASU website
  },
  {
    role: 'Application Engineer Intern',
    company: 'Genpact',
    duration: '01/2021 - 08/2021',
    points: [
      'Architected and deployed high-performance application solutions, leveraging agile methodologies to enhance system throughput by 30%.',
      'Conducted sophisticated regression testing and iterative code refactoring, which significantly reduced processing time and improved user experience across key software modules.'
    ],
    logo: Genpact,
    url: 'https://www.genpact.com' // URL to Genpact website
  }
];

const Experience = () => {
  return (
    <div className='experience bg-black w-full text-white pt-4 mt-0 overflow-x-hidden' id='experience'>
      <div className='pt-12 sm:px-16'>
        <p className='font-light'>MY JOURNEY SO FAR.</p>
        <h2 className='text-4xl sm:text-5xl font-extrabold mt-2'>Work Experience.</h2>
      </div>
      <VerticalTimeline className='mt-9'>
        {experiences.map((experience, index) => (
          <VerticalTimelineElement
            key={index}
            className="relative vertical-timeline-element--work"
            contentStyle={{ background: "#1d1836", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  #232631" }}
            date={experience.duration}
            iconStyle={{ background: '#fff' }}
            icon={
              <a className='flex justify-center items-center w-full h-full' href={experience.url} target='_blank' rel='noreferrer'>
                <img src={experience.logo} alt={experience.company} className='w-[60%] h-[60%] object-contain' />
              </a>
            }
          >
            <div>
              <h3 className='text-white text-[24px] font-bold'>{experience.role}</h3>
              <p className='text-secondary text-[16px] font-semibold' style={{ margin: 0 }}>{experience.company}</p>
            </div>
            <ul className='mt-5 list-disc ml-5 space-y-2'>
              {experience.points.map((point, idx) => (
                <li key={idx} className='text-white-100 text-[14px] pl-1 tracking-wider'>{point}</li>
              ))}
            </ul>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  )
}

export default Experience;
