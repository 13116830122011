import React from 'react';
import '../App.css';

// Import custom icons
import JavaIcon from '../assets/java.png';
import PythonIcon from '../assets/python.png';
import CppIcon from '../assets/c++.png';
import RIcon from '../assets/r.png';
import SwiftIcon from '../assets/swift.png';
import HtmlIcon from '../assets/html.png';
import CssIcon from '../assets/css.png';
import JsIcon from '../assets/js.png';
import ReactIcon from '../assets/react.png';
import NodeJsIcon from '../assets/nodejs.png';
import MatlabIcon from '../assets/matlab.png';
import NextJsIcon from '../assets/next.png';
import PostgresIcon from '../assets/post.png';
import MySQLIcon from '../assets/mysql.png';
import MongoDBIcon from '../assets/mongo.png';
import FirebaseIcon from '../assets/firebase.png';
import AwsIcon from '../assets/aws.png';
import JiraIcon from '../assets/jira.png';
import GitIcon from '../assets/git.png';
import AzureIcon from '../assets/azure.png';
import AstahIcon from '../assets/astah.png';
import WindowsIcon from '../assets/windows.png';
import MacOSIcon from '../assets/macos.png';
import LinuxIcon from '../assets/linux.png';
import UnixIcon from '../assets/unix.png';
import SeleniumIcon from '../assets/selenium.png';

import MachineLearningIcon from '../assets/ml.png';
import WebAppsIcon from '../assets/ui.png';
import DataScienceIcon from '../assets/ds.png';
import UxUiDesignIcon from '../assets/ui.png';
import RestAPIIcon from '../assets/rest.png';
import BigDataIcon from '../assets/bigdata.png';
import QATestingIcon from '../assets/qa.png';

const skills = {
  Languages: [
    { name: 'Java', icon: <img src={JavaIcon} alt="Java" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Python', icon: <img src={PythonIcon} alt="Python" style={{ width: '20px', height: '20px' }} /> },
    { name: 'C++', icon: <img src={CppIcon} alt="C++" style={{ width: '20px', height: '20px' }} /> },
    { name: 'R', icon: <img src={RIcon} alt="R" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Swift', icon: <img src={SwiftIcon} alt="Swift" style={{ width: '20px', height: '20px' }} /> },
    { name: 'HTML', icon: <img src={HtmlIcon} alt="HTML" style={{ width: '20px', height: '20px' }} /> },
    { name: 'CSS', icon: <img src={CssIcon} alt="CSS" style={{ width: '20px', height: '20px' }} /> },
    { name: 'JavaScript', icon: <img src={JsIcon} alt="JavaScript" style={{ width: '20px', height: '20px' }} /> },
    { name: 'React.js', icon: <img src={ReactIcon} alt="React.js" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Next.js', icon: <img src={NextJsIcon} alt="Next.js" style={{ width: '20px', height: '20px' }} /> }, // <-- Added Next.js
    { name: 'Node.js', icon: <img src={NodeJsIcon} alt="Node.js" style={{ width: '20px', height: '20px' }} /> },
    { name: 'MATLAB', icon: <img src={MatlabIcon} alt="MATLAB" style={{ width: '20px', height: '20px' }} /> },
  ],
  Databases: [
    { name: 'PostgreSQL', icon: <img src={PostgresIcon} alt="PostgreSQL" style={{ width: '20px', height: '20px' }} /> },
    { name: 'MySQL', icon: <img src={MySQLIcon} alt="MySQL" style={{ width: '20px', height: '20px' }} /> },
    { name: 'MongoDB', icon: <img src={MongoDBIcon} alt="MongoDB" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Firebase', icon: <img src={FirebaseIcon} alt="Firebase" style={{ width: '20px', height: '20px' }} /> },
  ],
  'Developer Tools and OS': [
    { name: 'AWS', icon: <img src={AwsIcon} alt="AWS" style={{ width: '20px', height: '20px' }} /> },
    { name: 'JIRA', icon: <img src={JiraIcon} alt="JIRA" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Git', icon: <img src={GitIcon} alt="Git" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Azure', icon: <img src={AzureIcon} alt="Azure" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Astah', icon: <img src={AstahIcon} alt="Astah" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Windows', icon: <img src={WindowsIcon} alt="Windows" style={{ width: '20px', height: '20px' }} /> },
    { name: 'MacOS', icon: <img src={MacOSIcon} alt="MacOS" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Linux', icon: <img src={LinuxIcon} alt="Linux" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Unix', icon: <img src={UnixIcon} alt="Unix" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Selenium', icon: <img src={SeleniumIcon} alt="Selenium" style={{ width: '20px', height: '20px' }} /> },
  ],
  Other: [
    { name: 'Machine Learning', icon: <img src={MachineLearningIcon} alt="Machine Learning" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Web Applications', icon: <img src={WebAppsIcon} alt="Web Applications" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Data Science', icon: <img src={DataScienceIcon} alt="Data Science" style={{ width: '20px', height: '20px' }} /> },
    { name: 'UI/UX design', icon: <img src={UxUiDesignIcon} alt="UI/UX design" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Rest-APIs', icon: <img src={RestAPIIcon} alt="Rest-APIs" style={{ width: '20px', height: '20px' }} /> },
    { name: 'Big Data', icon: <img src={BigDataIcon} alt="Big Data" style={{ width: '20px', height: '20px' }} /> },
    { name: 'QA Testing', icon: <img src={QATestingIcon} alt="QA Testing" style={{ width: '20px', height: '20px' }} /> },
  ],
};

const About = () => {
  return (
    <div id='about' className='bg-black w-full text-white sm:flex sm:justify-around about py-12 mt-0 overflow-x-hidden'>
      <div className='flex flex-col justify-around'>
        <div className='sm:px-16 px-2'>
          <h2 className='text-4xl sm:text-5xl font-extrabold mt-2'>Introduction</h2>
          <p className='mt-3 mb-6 text-[17px] max-w-3xl leading-[30px]'>
            👨‍💻 Hi, I'm Yuvraj Bhatia, a rising senior at Arizona State University, majoring in Computer Science. I am passionate about leveraging machine learning, web development, and data science to solve real-world problems. With hands-on experience in languages like Python, Java, and C++, and tools such as Flask, React, and TensorFlow, I thrive on creating efficient and impactful applications. I am eager to bring my skills and enthusiasm to new challenges and opportunities in the tech industry.🚀.
            <br />
          </p>
        </div>
        <div className='mt-10 sm:px-16 px-2 skills-container'>
          {Object.keys(skills).map((category) => (
            <div key={category} className='mb-8'>
              <h3 className='text-3xl font-semibold mb-4'>{category}</h3>
              <div className='flex flex-wrap gap-4'>
                {skills[category].map((skill) => (
                  <span key={skill.name} className='bg-gray-800 text-white py-2 px-4 rounded-lg shadow flex items-center gap-2 skill-item'>
                    {skill.icon}
                    {skill.name}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
