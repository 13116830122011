import React from 'react';
import { FaGithub, FaTwitter } from 'react-icons/fa';
import { AiFillLinkedin } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';


const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<div className='bg-slate-900 p-4' style={{ cursor: 'default' }}>
			<div className='flex justify-between flex-wrap gap-4'>
				<p className='text-white text-center w-full sm:w-auto font-light'>© {year} Yuvraj. All rights reserved.</p>
				<div className='text-white flex justify-around sm:w-[250px] w-full'>
					
				<a href="mailto:yuvrajbhatia.work@gmail.com" className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
       				<FiMail className='text-2xl' />		
      				</a>
      			<a href="https://x.com/yuvraj_893" target='_blank' rel='noreferrer' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
        			<FaTwitter className='text-xl' />
     				 </a>
     			<a href="https://www.linkedin.com/in/yuvraj-bhatia-0311651b1/" target='_blank' rel='noreferrer' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
      			  	<AiFillLinkedin className='text-xl' />
      				</a>
      			<a href="https://github.com/yuvraj893" target='_blank' rel='noreferrer' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
        			<FaGithub className='text-xl' />
      				</a>
				</div>
			</div>
		</div>
	);
}

export default Footer;