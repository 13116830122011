import React from 'react';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <section id="home">
        <Home />
      </section>
      <div className="transition-section"></div>
      <section id="about">
        <About />
      </section>
      <div className="transition-section"></div>
      <section id="projects">
        <Projects />
      </section>
      <div className="transition-section"></div>
      <section id="experience">
        <Experience />
      </section>
      <div className="transition-section"></div>
      <section id="contact">
        <Contact />
      </section>
      <section id="footer">
        <Footer />
      </section>
    </div>
  );
};

export default App;
