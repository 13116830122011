import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [navBackground, setNavBackground] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavBackground(true);
    } else {
      setNavBackground(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ease-in-out ${navBackground ? 'bg-blue-900 shadow-lg' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-2 flex justify-center items-center">
        <div className="space-x-6 flex justify-center items-center">
          <a href="#home" className="text-lg text-white hover:text-gray-300">Home</a>
          <a href="#about" className="text-lg text-white hover:text-gray-300">About</a>
          <a href="#projects" className="text-lg text-white hover:text-gray-300">Projects</a>
          <a href="#experience" className="text-lg text-white hover:text-gray-300">Experience</a>
          <a href="#contact" className="text-lg text-white hover:text-gray-300">Contact</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
