import React, { useEffect, useRef, useState } from "react";
import { FaGithub } from 'react-icons/fa';
import weather from '../assets/weather.png';
import realestate from '../assets/realestate.png';
import techbot from '../assets/techassist.png';
import titanic from '../assets/titanic.png';
import ocr from '../assets/ocr.png';
import ad from '../assets/ad.png'; 
import portfolio from '../assets/portfolio.png';
import unboxme from '../assets/unboxme.png';
import effortlogger from '../assets/effortlogger.png';

const ProjectCard = ({ image, title, description, git, technologies, fadeIn, delay }) => {
    return (
        <div
            className={`project-card max-w-[200px] sm:max-w-[220px] md:max-w-[240px] h-full bg-gray-900 border border-neutral-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'} hover:transform hover:scale-105 hover:shadow-xl`} 
            style={{ transitionDelay: `${delay}s` }}
        >
            <img className="w-full rounded-t-lg h-[120px] object-cover" src={image} alt={title} />
            <div className="p-2 sm:p-3">
                <h5 className="text-sm sm:text-md md:text-lg font-bold tracking-tight text-white bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-pink-500">{title}</h5>
                <p className="font-light text-[11px] sm:text-[12px] md:text-[13px] text-gray-300 dark:text-gray-400 mt-2">{description}</p>
            </div>
            <div className='m-1 sm:m-2 lg:m-3 flex justify-between items-center'>
                <div className='flex flex-wrap gap-1'>
                    {technologies.map((tag, index) => (
                        <p
                            key={`${index}-${tag}`}
                            className='text-[10px] text-blue-500'
                        >
                            #{tag}
                        </p>
                    ))}
                </div>
            </div>
            {git && (
                <div className="m-2 flex justify-center">
                    <a
                        href={git}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center px-3 py-2 space-x-2 text-xs font-semibold text-white transition bg-gradient-to-r from-blue-500 to-pink-500 rounded-lg hover:from-blue-400 hover:to-pink-400"
                    >
                        <FaGithub size={14} />
                        <span>GitHub</span>
                    </a>
                </div>
            )}
        </div>
    );
};

const Projects = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [showAllProjects, setShowAllProjects] = useState(false);
    const ref = useRef();

    const projectsToShow = showAllProjects ? project : project.slice(0, 5);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0.1,
            }
        );

        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div className="bg-black w-full py-12 mt-0" id="projects" ref={ref}>
            <div className="pt-12 sm:px-16 text-left">
                <p className='font-light text-white'>MY WORK SO FAR.</p>
                <h2 className='text-4xl sm:text-5xl font-extrabold mt-2 text-white'>Projects.</h2>
            </div>
            <div className={`flex flex-wrap justify-center gap-5 m-4 p-4 transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                {projectsToShow.map((item, index) => (
                    <ProjectCard
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        git={item.git}
                        technologies={item.technologies}
                        fadeIn={isVisible}
                        delay={index * 0.1}
                    />
                ))}
            </div>
            {!showAllProjects && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setShowAllProjects(true)}
                        className="text-white bg-gradient-to-r from-blue-500 to-pink-500 px-6 py-2 rounded-lg hover:from-blue-400 hover:to-pink-400 transition-transform duration-500 ease-in-out transform hover:scale-105"
                    >
                        View All Projects
                    </button>
                </div>
            )}
            {showAllProjects && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setShowAllProjects(false)}
                        className="text-white bg-gradient-to-r from-blue-500 to-pink-500 px-6 py-2 rounded-lg hover:from-blue-400 hover:to-pink-400 transition-transform duration-500 ease-in-out transform hover:scale-105"
                    >
                        Hide Projects
                    </button>
                </div>
            )}
        </div>
    );
}

export const project = [
    {
        title: 'Ad Campaign Optimizer', 
        description: 'At my internship, I am working on a machine learning-based application that optimizes digital ad campaigns by analyzing user behavior, targeting efficiency, and performance metrics to improve ROI. (Dochase ADX)',
        image: ad, 
        git: '', 
        technologies: ['Python', 'Flask', 'Machine Learning', 'SQLAlchemy', 'Pandas', 'NLP']
    },
    {
        title: 'Dynamic Weather Forecaster',
        description: 'Python GUI application using Tkinter for real-time weather visualization and forecasting. (Personal Project)',
        image: weather,
        git: 'https://github.com/yuvraj893/Dynamic-Weather-Forecaster',
        technologies: ['Python', 'Tkinter', 'OpenWeatherMap API']
    },
    {
        title: 'Real Estate Valuation Model',
        description: 'Predictive model using linear regression to analyze property listings with 95% accuracy rate. (ML Challenge Hackathon)',
        image: realestate,
        git: 'https://github.com/yuvraj893/Real-Estate-Valuation-Model',
        technologies: ['Python', 'Pandas', 'NumPy', 'Scikit-learn', 'Matplotlib']
    },
    {
        title: 'Tech Assist Bot',
        description: 'Conversational AI assistant using Flask and GPT-3.5 API for job search assistance. (Personal Project)',
        image: techbot,
        git: 'https://github.com/yuvraj893/Tech-Assist-Bot',
        technologies: ['Python', 'Flask', 'HTML', 'JavaScript', 'SQLAlchemy', 'OpenAI API', 'NLP', 'Heroku']
    },
    {
        title: 'Titanic Accident Analysis',
        description: 'Logistic regression model to predict survival patterns in Titanic dataset with 80% accuracy. (ML Challenge Hackathon)',
        image: titanic,
        git: 'https://github.com/yuvraj893/Titanic-Accident-Analysis',
        technologies: ['Python', 'Pandas', 'Seaborn', 'Scikit-learn']
    },
    {
        title: 'Optical Text Analyzer',
        description: 'OCR program using Python, processing 1,000 images daily for document digitization efficiency. (Personal Project)',
        image: ocr,
        git: 'https://github.com/yuvraj893/Optical-Text-Analyzer',
        technologies: ['Python', 'Tesseract-OCR', 'OpenCV', 'Pandas']
    },
    {
        title: 'Personal Portfolio',
        description: 'A personal portfolio website built using React.js to showcase my work, skills, and projects with smooth UI/UX and responsive design. (Personal Project)',
        image: portfolio,
        git: 'https://github.com/yuvraj893/Personal-Portfolio',
        technologies: ['React.js', 'TailwindCSS', 'JavaScript', 'CSS']
    },
    {
        title: 'Unboxme - Rules-Based Advertising SaaS Product',
        description: 'Developed a SaaS platform integrating machine learning to optimize Amazon ad campaigns with predictive analytics for better performance. (Capstone Project)',
        image: unboxme,
        git: '',
        technologies: ['Python', 'Machine Learning', 'React.js', 'AWS', 'SQL']
    },
    {
        title: 'Effort Logger',
        description: 'An effort-tracking web application designed to log and analyze team members\' contributions to projects, with features to improve efficiency and communication. (Course Project)',
        image: effortlogger,
        git: '',
        technologies: ['React.js', 'Node.js', 'Express', 'MongoDB']
    }
]

export default Projects;
