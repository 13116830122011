import React from 'react';
import { useState, useEffect, useRef } from 'react';
import myPhoto from '../assets/profile-pic2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faGithub } from '@fortawesome/free-brands-svg-icons';

const Home = () => {
  const textToType = "HI, I AM YUVRAJ BHATIA.";
  const subTextToType = "WELCOME TO MY TECH PORTFOLIO. DIVE INTO MY PROJECTS AND EXPERIENCE THE WORLD OF INNOVATION AND CREATIVITY.";
  const ref = useRef(0);
  const subRef = useRef(0);
  const [text, setText] = useState('');
  const [subText, setSubText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current < textToType.length) {
        ref.current++;
        setText((prevText) => prevText + textToType[ref.current - 1]);
      } else if (subRef.current < subTextToType.length) {
        subRef.current++;
        setSubText((prevText) => prevText + subTextToType[subRef.current - 1]);
      } else {
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='relative z-0 w-screen h-screen flex flex-col' id='home' style={{ background: 'linear-gradient(to right, #001f3f, #004080)' }}>
      <div className='left-section w-full flex flex-col items-start justify-center p-4' style={{ marginTop: '4rem' }}>
        <div className='text-container mb-4' style={{ paddingLeft: '4rem' }}>
          <h1 className='text-4xl sm:text-5xl font-extrabold mt-2 text-white'>{text}</h1>
          <h2 className='text-xl sm:text-2xl font-bold text-white'>{subText}</h2>
        </div>
      </div>
      <div className='right-section w-1/3 h-full flex flex-col items-center justify-center p-4' style={{ position: 'absolute', right: 0, top: 0 }}>
        <div className='photo-container mb-4'>
          <img src={myPhoto} alt="YUVRAJ BHATIA" className='rounded-full w-56 h-56 object-cover shadow-lg' />
        </div>
        <div className='text-center mb-12'>
          <h2 className='text-lg sm:text-3xl font-bold text-white'>YUVRAJ BHATIA</h2>
        </div>
        <div className='text-center mb-12'>
          <p className='text-sm sm:text-lg text-white mt-7'>
            B.S Computer Science<br />
            Arizona State University<br />
            📍 Tempe, AZ<br />
            Expected Graduation: May 2025
          </p> 
        </div>
        <div className='text-center'>
          <p className='text-sm sm:text-lg text-white mt-12'>yuvrajbhatia.work@gmail.com<br />+1 480-876-1664</p>
          <div className='flex justify-center mt-4'>
            <a href="https://www.linkedin.com/in/yuvraj-bhatia-0311651b1/" target='_blank' rel='noreferrer' className='text-white mx-2 icon-link'>
              <FontAwesomeIcon icon={faLinkedin} size="2x" className='linkedin-icon' />
            </a>
            <a href="https://x.com/yuvraj_893" target='_blank' rel='noreferrer' className='text-white mx-2 icon-link'>
              <FontAwesomeIcon icon={faTwitter} size="2x" className='twitter-icon' />
            </a>
            <a href="https://github.com/yuvraj893" target='_blank' rel='noreferrer' className='text-white mx-2 icon-link'>
              <FontAwesomeIcon icon={faGithub} size="2x" className='github-icon' />
            </a>
          </div>
          <a href='https://drive.google.com/file/d/1n5C2BFIooyGdcdDSh1ZM2yWImR82-zX2/view?usp=sharing' target='_blank' rel='noreferrer' className='mt-4 inline-block px-6 py-3 bg-dark-blue text-lg sm:text-xl text-white rounded-lg hover:bg-blue-700 transition'>
            Resume
          </a>
        </div>
      </div>
      <ul className="circles" style={{ zIndex: 1 }}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}

export default Home;
